body {
    overflow-x: hidden;
}

ul {
    padding-left: 0px;
    margin: 0;
    list-style: none;
}

ul li {
    margin: 5px;
    margin-left: 0;
}

ul li::before {
    content: '•';
    margin-right: 6px;
    margin-left: 1px;
    margin-top: 2px;
    float: left;
}

ul li li {
    padding-left: 20px;
}

.modo-dark ul li::before {
    color: #fff;
}